<template>
  <div class="personaList h-full">
    <div class="h-full flex items-center justify-center" v-if="loading">
      <i class="circle-loader"></i>
    </div>
    <!-- we need 8 zone list here -->
    <div class="personaList__inner" v-else>
      <div class="zoneContainer">
        <a-divider>Familiar</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Familiar"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected1" :options="Familiar" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Friendly</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Friendly"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected2" :options="Friendly" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Fun</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Fun"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected3" :options="Fun" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Inspiring</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Inspiring"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected4" :options="Inspiring" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Interesting</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Interesting"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected5" :options="Interesting" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Knowledgeable</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Knowledgeable"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected6" :options="Knowledgeable" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Nurturing</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Nurturing"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected7" :options="Nurturing" /> -->
      </div>
      <div class="zoneContainer">
        <a-divider>Trustworthy</a-divider>
        <div class="attributes">
          <a-checkable-tag
            v-for="attr in Trustworthy"
            v-model="attr.selected"
            :key="attr.attributeID"
          >
            {{ attr.AttributeLabel }}
          </a-checkable-tag>
        </div>
        <!-- <a-checkbox-group v-model="selected8" :options="Trustworthy" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonaList",
  props: {
    personaAttrs: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
    rmAttr: Number,
  },
  computed: {
    Familiar() {
      return this.personaAttrs.filter((attr) => attr.zoneName === "Familiar");
    },
    Fun() {
      return this.personaAttrs.filter((attr) => attr.zoneName === "Fun");
    },
    Inspiring() {
      return this.personaAttrs.filter((attr) => attr.zoneName === "Inspiring");
    },
    Nurturing() {
      return this.personaAttrs.filter((attr) => attr.zoneName === "Nurturing");
    },
    Interesting() {
      return this.personaAttrs.filter(
        (attr) => attr.zoneName === "Interesting"
      );
    },
    Friendly() {
      return this.personaAttrs.filter((attr) => attr.zoneName === "Friendly");
    },
    Trustworthy() {
      return this.personaAttrs.filter(
        (attr) => attr.zoneName === "Trustworthy"
      );
    },
    Knowledgeable() {
      return this.personaAttrs.filter(
        (attr) => attr.zoneName === "Knowledgeable"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.personaList__inner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  column-gap: 15px;
  row-gap: 35px;
  padding: 2em 15px;
}
.ant-checkbox-group {
  display: grid;
  grid-gap: 8px;
  overflow: auto;
  padding-left: 10px;
  padding-bottom: 10px;
  grid-template-rows: repeat(auto-fill, 25px);
}

.zoneContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-top: none;
  max-height: 250px;
}

.ant-divider-horizontal {
  margin-top: -12px;
}

.attributes {
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
  padding-top: 0.5em;
  row-gap: 0.5em;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #787878;
    }
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbbbbb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-tag {
    border-radius: 2px;
    padding: 0.2em 0.5em;
    font-size: 1em;
    cursor: pointer;
  }
}
</style>
